import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Akula from "../Assest/Akula.jpg";
import Poola from "../Assest/Poola.jpg";
import Ram from "../Assest/Ram.jpg";
import Deepika from "../Assest/Untitled.png";
import Mission from "../Assest/Mission.png";
import Value from "../Assest/Value.png";
import Vision from "../Assest/Vision.png";
export default function About() {
  return (
    <div>
      <div className="header"></div>
      <div className="about">
        <h1>About Us</h1>

        <h2 className="about__Why">
          Why We Can Win Global is an extension of{" "}
          <span>
            <a href="http://wecanwin.ca/"> We Can Win </a>
          </span>
        </h2>

        <h2 className="about__Title">Mission Of We Can Win</h2>

        <p className="about__Paragraph">
          Every person is capable and can contribute to an integrated way of
          living that benefits themselves and others simultaneously. spending a
          life building many talents and contributing, learning to work
          efficiently and accurately to become healthy, safe, hopeful, engaged
          and contributing members of their communities.
        </p>
        <h1 style={{ margin: "50px auto" }}>Introduction</h1>
        <iframe
          title="Intro"
          src="https://www.youtube.com/embed/lyVOS_2Y32E"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{ marginBottom: "50px" }}
        ></iframe>

        <h2 className="about__Title" style={{ marginTop: "50px" }}>
          Mission Of We Can Win Global
        </h2>

        <p className="about__Paragraph">
          {" "}
          Our mission extends to Community mobilization, where solidarity
          occurs, Prioritizing viable economic opportunities through, planning
          and enhanced decision making, Strengthening community by identifying
          economic competitiveness through implementation of identified
          priorities and provide expert advice, Creating an assessment model for
          students to develop the domain knowledge, design assessment tools, and
          report assessment data after research in diversified environment
          Create Short medium and long term goals, resulting in stimulated and
          enhanced growth.
        </p>
        <div className="about__Info">
          <div className="about__InfoDetails">
            <img src={Mission} alt="" />
            <h2>Our Mission</h2>
            <p>
              Identify the potentials of all individuals and support to achieve
              their objectives to create a synergy environment.
            </p>
          </div>

          <div className="about__InfoDetails">
            <img src={Vision} alt="" />
            <h2>Our Vision</h2>
            <p>Build empowering, compassionate, and inclusive communities.</p>
          </div>

          <div className="about__InfoDetails">
            <img src={Value} alt="" />
            <h2>Our Values</h2>
            <p>
              <ul>
                <li>Accountability</li>
                <li>Diversity</li>
                <li>Transparency</li>
                <li>Inclusion</li>
                <li>Social Justice</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div className="about__build">
        <h1>Let's build somthing together</h1>
        <button>
          {" "}
          <Link to="/contact" className="about-Link">
            Get In Touch
          </Link>{" "}
        </button>

        <hr />
      </div>
      <div className="about__Team">
        <h1 className="about___H1">Our Team</h1>

        <div className="about__TeamInfo">
          <div className="about__TeamInfoDetails">
            <img src={Akula} alt="" />
            <h1>Sukanya Akula</h1>
            <h2>Executive Director</h2>
            <p>
              Certified Project Management Professional (PMP) and Certified
              Business Analyst Professional (CBAP) has Project Management and
              Business Analysis Experience of 15 + years With an experience
              working in various Organizations Financial, Government, Not for
              Profit Organizations In her Career she has identified needs
              assessment, prepared business case with feasibility, cost benefit
              analysis risks, mitigating actions with return on investments.
              Prepared Project Plans, training documents for Internal and
              External stakeholders, able to determine the root cause of a
              problem following processes, procedures. She is capable to work
              through complex strategic projects and businesses.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <img src={Poola} alt="" />
            <h1>Poola Ramnaraine</h1>
            <h2>Advisory Committee Member</h2>
            <p>
              A highly devoted and ambitious professional with a social work
              background, eleven years of Administrative and background, 10
              years of customer service experience, in facilitating workshops
              for diverse backgrounds. Nevertheless, experienced in translating
              and documentation processing to assist newcomers and settlers. He
              has his diploma in Social Service Worker and a certification in
              Safe Talk Suicide Prevention, strong presentational skills along
              with the ability to present and hold workshops for motivational
              speaking.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <img src={Ram} alt="" />
            <h1>Ram Verankki</h1>
            <h2>Program Manager</h2>
            <p>
              Certified Project Management Professional (PMP), ITIL,
              Agile(SCRUM) certified, experience of twenty plus years in
              Electronics and Information Technology Industry as a Project Lead,
              Project Coordinator manager various capacities in Government
              Organizations. Internationally worked at Electronics Corporation
              of India, York University Toronto, Canada, Ministry of Natural
              Resources (MNR) Peterborough Canada, Child and Family Welfare
              Services Government of Manitoba Winnipeg and TELUS.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <img src={Deepika} alt="" />
            <h1> Deepika Verankki</h1>
            <h2>Program Coardinator</h2>
            <p>
              A George brown graduate in Computer System Technology, A+ and CCNA
              certified professional, experience working in various
              organizations as Can Crops Inc, Bluestar Consultancy and We Can
              Win. She is passionate in computer training with hardware,
              software and networking, explaining to students. She has excellent
              interpersonal, communication and strong customer service skills.
              She has passionately volunteered for ‘We Can Win’, played a major
              role in document management for the Website creation.
            </p>
          </div>
        </div>
      </div>

      <div className="about__Team">
        <h1 className="about__Team__h1">Work Collaborators:</h1>

        <div className="about__TeamInfo">
          <div className="about__TeamInfoDetails">
            <h2>Paul Marshall</h2>
            <p>
              Managing Director of Canadian Educational Services- an
              organization that is connected with colleges and Universities and
              helps advice people of career training and the related financial
              aid and grants from the government. He has a Masters in Philosophy
              and Psychology.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <h2>Chantal Duval</h2>
            <p>
              hails from Montreal, Quebec. After working in several customer
              services and hospitality positions as well as in health care, she
              came to Ontario 12 years ago.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <h2>Tutul (Mohiul) Khan</h2>
            <p>
              Tutul worked with Royal Bank of Canada for 15 years in National
              Fraud Detection Centre. He graduated in Accounting with Honours
              and Masters in Business Finance.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <h2>Kunal Patel</h2>
            <p>
              Kunal started an Educational Counselling Services in Canada and
              helped quiet a large number of new immigrants to settle in Canada.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <h2>Manpreet Kaur</h2>
            <p>
              is working for different non-profit organizations such as
              “Community Matters Toronto”, “St. James Town”, “Young-Ge” trying
              to help the youth community.
            </p>
          </div>

          <div className="about__TeamInfoDetails">
            <h2>Ravi</h2>
            <p>
              Experienced in accounting and financial skills,Ravi has strong
              communication skills, is able to build and maintain working
              relationships with people from diverse cultures and backgrounds .
            </p>
          </div>
          <hr style={{ width: "700px" }} />
          <div className="about__Paragraph">
            <h1 className="about__TeamInfoDetailsStyle">Work Collaborators:</h1>
            <h2>Sharon</h2>

            <p>
              A highly dedicated and motivated professional with eight years’
              experience in the educational and employment services fields
              supporting clients from diverse cultures.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
