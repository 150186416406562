import React from "react";
import "./style.css";
export default function Map() {
  return (
    <div className="map">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d285.99583223675154!2d-79.21828161118006!3d43.738452726241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xeba32bda93d7c7fd!2sWe%20Can%20Win!5e0!3m2!1sen!2sca!4v1613500291870!5m2!1sen!2sca"
        allowFullscreen
        style={{ frameborder: "0" }}
      />
      <div className="map__Bottom">
        <h1>
          <strong>Contact:</strong> info@wecanwin.ca{" "}
        </h1>
        <h1>
          <strong>Phone:</strong> +1(647)667-8154
        </h1>
        <h1>
          <strong>Hours</strong> 9am - 5pm Monday to Friday
        </h1>
      </div>
    </div>
  );
}
