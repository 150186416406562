import React from "react";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ContactUs from "./Contact";
export const words = (props) => {
  return (
    <>
      <div className="course__Container">
        <div>
          <Link className="back" to="/Courses">
            <ChevronLeftIcon />
            <h3>Back</h3>
          </Link>
        </div>
        <div className="course__Row"></div>
        <div className="course">
          <div className="course__Hero">
            <h1> Microsoft Word Tutorials</h1>
            <hr className="hero__HR" />
            <h2>Learn Formatting, Document Design, Shortcuts & More</h2>

            <button>Click Here</button>
            <h3>Starting at CA$0.00/Month</h3>
          </div>

          <div className="course__title">
            <h1>About The Course</h1>
            <p>
              Since every computer may have various versions of Microsoft Office
              such as 2007, 2010, 2013, and 2016, the lesson here being prepared
              is from Microsoft Word 2010.
            </p>
            <p>
              In these basic steps of using Microsoft Word, surely it will be
              quite easy and straightforward to walk through the steps in any of
              these versions mentioned above as this level focuses on creating,
              editing, saving, and printing documents as well as formatting
              texts and paragraphs.
            </p>

            <p>
              So listed below, are the following steps on how to use Word
              including the “shortcut key(s)” as other alternatives.
            </p>

            <h1>Microsoft Word Level 1</h1>

            <p>
              You’ll need word-processing software such as Word, part of
              Microsoft Office, to produce and edit text documents. Other tools
              include the word-processing program used in Microsoft Works suite,
              which often is provided in computer installations, and Word
              Perfect.
            </p>

            <h1>Microsoft Word Level 2</h1>
            <p>
              Now that we have covered most part of the basics in the previous
              lesson, in this intermediate part of Word, we are going to be
              focusing on inserting page breaks, cover pages, tables, word art
              designs, mail merge, indents, and setting/adjusting tab.
            </p>

            <h1>(INTERMEDIATE)</h1>
            <p>
              Now that we have covered most part of the basics in the previous
              lesson, in this intermediate part of the Word, we are going to be
              focusing on inserting page breaks, cover pages, tables, word art
              designs, mail merge, indents, and setting/adjusting tab.
            </p>
          </div>

          <ContactUs className="contact__Courses" />
        </div>
      </div>
    </>
  );
};

export default words;
