import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";
import ContactUs from "./Contact";
export default function Leadership() {
  return (
    <>
      <div className="course__Container">
        <div>
          <Link className="back" to="/Courses">
            <ChevronLeftIcon />
            <h3>Back</h3>
          </Link>
        </div>

        <div className="course">
          <div className="course__Hero">
            <h1>Leadership Tutorials</h1>
            <hr className="hero__HR" />
            <h2>
              Designing completing slide, presentations, editing, sharing &
              more.
            </h2>

            <button>Click Here</button>
            <h3>Starting at CA$0.00/Month</h3>
          </div>

          <div className="course__title">
            <h1>Leadership Course</h1>
            <p className="leadership__qoutation">
              <strong>
                Changes in our lives are fraught with uncertainty but brimming
                with opportunity’{" "}
              </strong>
            </p>
            <p>
              You provide the tools, to work smarter instead, because sometimes
              you accomplish more by doing less. very factors that prevent you
              from fitting in can eventually help you stand out.
            </p>

            <p>
              In every decision you make and every goal you set, there are two
              easy ways to fail: having too little confidence and having too
              much confidence. Finding joy at work isn’t magic—it takes work.
              But it might feel a bit more like play Have you ever wanted to
              make a big change in your life but weren’t sure where to start?
              .To take control of your destiny and drive the change you want to
              see, you need to lay the groundwork, so you’re prepared to seize
              every opportunity that comes your way. And that means mastering
            </p>
            <p>
              “A lot of people feel that to be powerful is to exude radiate
              strength. the total opposite. To be powerful is to be powerless.
              It’s when you give everybody what you got.”
            </p>
            <p>
              “A lot of people feel that to be powerful is to exude radiate
              strength. the total opposite. To be powerful is to be powerless.
              It’s when you give everybody what you got.”
            </p>

            <p>
              ‘Create, develop, harness, sustain, lead, monitor Projects &
              Project Management
            </p>

            <p>
              ‘ Assist, guide advice mentor & lead career of your dreams to
              achieve success’ ation and monitoring phasesProject
              Name:Dignity,Empower skills development to enable participate in
              economic, cultural, social and political lives in our community.
            </p>

            <p>
              • Influence—make an impression: Develop a reputation that
              highlights what you stand for.
            </p>
            <p>• Negotiation—make a deal: Hone a win-win negotiating style.</p>

            <p>
              • Relationships—make a connection last: Nurture those connections
              you make along the way.
            </p>

            <p>
              determining your value: “You don’t have to go ask somebody else
              for permission. You have the power to be able to stick to your
              guns and demand your worth.” finding and understanding your why:
              “Time is the one asset we don’t own, we can’t buy, and we can
              never get back.”
            </p>

            <h1>How</h1>

            <p>
              Provided professional coaching advisors, and
              upgradation.Management skills improvement in existing skills.
            </p>
            <p>How Leadership is instilled by We Can Win?</p>

            <p>
              Provide programs and services to youth (15-29),Seniors,Women
              aligining with our vision & mission
            </p>

            <div className="workshop">
              <h1>Advancement of Education:</h1>
              <p>
                Support all the groups to become healthy, safe, hopeful, engaged
                and contributing members of their communities. Education and
                enhancing the careers, amd get trained in developing skills and
                related to requirement of each Individual 1-1 after assigning to
                our staff or voulnteers to teach.
              </p>
              <h1>Upgrade in Technical and computer skills</h1>
              <p>
                Promoted learning in existing job market in fields such as
                Accounting, Health care, Information Technology, Computer
                hardware, software, Computer troubleshooting, repairing,
              </p>
              <h1>Train in Microsoft office and internet usage skills.</h1>
              <p>
                We have out updated free training Programs in our website, and
                our Advisory groups inclusion of short sessions and classes
              </p>
              <h1>Three priorities of an Leader:</h1>
              <p>Operational: Time budget, results</p>
              <p>Transformational: Scale and improve processes</p>
              <p>Innovation: Evolve, adopt, experiment.</p>
              <p>
                “Life begins at the end of your comfort zone. This is the spirit
                that made me want to immigrate
              </p>
            </div>
            <p>
              Resume are predictors of success in life. To be clear, a Resume is
              very useful to understand what skills and experience a potential
              candidate has. However, it will not capture fully the most
              important things in life such as integrity, resilience, character,
              intellectual horsepower, emotional intelligence, street hustle,
              humility, empathy, and many other things that are essential for
              success.
            </p>
            <p>
              Go beyond Resume, our greatness does not show up on a piece of
              paper. It shows up in our pivotal choices in life and the stories
              behind them. I want to hear about the time you got bullied, and
              what you did. I want to hear about the time you cried because you
              fought for a dream or a belief. I want to hear about the time you
              failed miserably and what you learned. I want to hear about how
              far you travelled in life on nothing, but integrity, grit,
              courage, and hard work. Eg, , a Rockstar, is someone who wants to
              achieve the extraordinary, someone who is ready to climb the
              biggest mountains to unleash his/her potential, someone who lives
              to defy odds, someone who is willing to make the toughest
              sacrifices for a dream, someone who dares to be an original.
            </p>

            <h1>Discover Leadership</h1>

            <p>
              Project scope is the work required to output a project’s
              deliverable. Change happens, and project scope management includes
              the process to manage scope changes and make sure the project will
              still come in on time and within budget. Scope is often defined by
              a work breakdown structure, and changes should take place only
              through formal change control procedures.
            </p>
            <p>
              Develop a team and we need to be aligned to accomplish a goal and
              actually run the steps of the process to manage a project from
              idea to execution. One of the best things is getting real
              experience doing project management, members with different
              ambitions, all having to come together and, hopefully, turn in
              something at the end of the project that we are proud of.” To
              launch a Startup and build a business, It was very obvious that
              being successful at project management was the only way I was
              going to be able to build the company.
            </p>
            <p>
              you will get a lot further working together. Part of that comes
              from getting buy-in from your team and using the right tools to
              make sure you have accountability. it may take a few years or even
              a decade to accomplish. So it’s important to have accountability
              because it's the only way you'll know you're making progress
              toward your goal. Accountability keeps people aligned, keeps them
              motivated and helps the team understand what they've done but also
              where they still need to go.
            </p>
            <p>
              there is a lot of context switching, where over the course of a
              day, I could see five pitches, and each pitch involves a different
              industry, a different product and a different founder and team.
              There is a kind of agility required from me and the team. We need
              to be able to context switch every hour or so to some other
              company that we're evaluating or that we've invested in and are
              working with, in order to really be valuable and helpful to them.
              It’s a gift and a curse of the age we live in that we’re one tab
              away from another distraction, it’s made it a lot more natural,
              and we have a greater ability for that kind of rapid context
              switching.
            </p>
            <p>
              You do not need flashy websites or slick business cards to market
              yourselves. a few strategic tasks can generate real results,
            </p>
            <p>
              First, spread the word to your professional contacts that you’re
              available for freelance assignments, freelance positions are often
              advertised and filled through word of mouth. Tell your
              professional colleagues that you’re looking to freelance, and
              they’ll be well positioned to not only alert you to an opportunity
              but also speak to your experience planning and managing projects,
              if they know the hiring manager. That’s a win-win.
            </p>
            <p>
              Second, optimize your online profile so that any hiring manager
              searching for a particular skill set—say, risk management in
              public-private partner-ships—will more easily find your résumé.
              Make sure to highlight any project management certifications, such
              as PMI’s Project Management Professional (PMP)® certification. Not
              only do certifications validate your skills—they provide an
              effective keyword when talent seekers filter through résumés and
              online professional profiles to identify the perfect fit. And if
              you’ve been planning to sit for a certification, consider shifting
              that to the top of your to-do list.
            </p>
            <p>
              “One of the silver linings of the pandemic is that people who have
              felt very uncomfortable going to events and striking up
              conversations with strangers now have a legitimate excuse to do
              more virtual networking, like attending webinars and online events
            </p>

            <p className="leadership__qoutation">
              <strong className="leadership__qoutationStrong">
                LEADER GIVE ADVICE AND EMPOWER THEM TO TAKE THEIR OWN DECISIONS
              </strong>
            </p>

            <div className="powerPoint__About">
              <div class="parent">
                <ul>
                  <li>PM Context in Leadership</li>
                  <li>Align as in PM Processes:</li>
                  <li>Integration</li>
                  <li>Scope</li>
                  <li>Time</li>
                </ul>
              </div>

              <div class="parent">
                <ul>
                  <li>Cost</li>
                  <li>Quality</li>
                  <li>Communication</li>
                  <li>Risk</li>
                  <li>Procurement</li>
                </ul>
              </div>
            </div>

            <p>
              Leadership related to Business Analysis:Business Analysts, need to
              bridge & gain knowledge to enter the field.
            </p>

            <p>
              Brand: Assist guide advice by harness, mentor, and lead you to
              your dreams, provide Training to the aspiring future Jr.Business
              Analysts and Jr.Project Managers voluntarily.
            </p>

            <div class="parent">
              <ul>
                <li>guide to gain career opportunities</li>
                <li>
                  Teach Business Analysis, related career positions related
                  services
                </li>
                <li>Usage of Tools</li>
                <li>Business Analytics</li>
                <li>Other IT related skills</li>
              </ul>
            </div>

            <p>
              Play a role as actively as possible in an online learning as
              Learning Managing System to clients The participation itself is
              very important for leading successful projects
            </p>

            <p>
              Examples to lead: Identify the top three problems the customers
              want addressed and describe them. What are the jobs your customers
              need done on your offerings that they may not be satisfied with
              your competitors offering, or the way of their offering? able to
              communicate the problems and the proposed solutions back to the
              Client.
            </p>
          </div>

          <ContactUs className="contact__Courses" />
        </div>
      </div>
    </>
  );
}
