import React from "react";
import "./style.css";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import GroupIcon from "@material-ui/icons/Group";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
export default function otherServices() {
  return (
    <div className="otherServices">
      <div className="otherServices__First">
        <h1>Services</h1>
        <h2>
          Start Your <span>Journey Here</span>
        </h2>
        <p className="services_Par">
          With We Can Will Global, we are extending our services to better
          assest you with your career goals and needs
        </p>
      </div>

      <div className="otherServices__Second">
        <div className="otherServices__Top">
          <GroupIcon style={{ fontSize: "36px" }} />
          <h1>Consulting</h1>
          <p className="services_Par">
            We provide premium consulation to achieve your goals and dreams,
            followed by bio-weekly appoiments to improve your process and
            expernice.
          </p>
        </div>
        <div className="otherServices__bottom">
          <EmojiPeopleIcon style={{ fontSize: "36px" }} />

          <h1>Career Developments</h1>
          <p className="services_Par">
            Start your career's journey with us by setting up plans on achieving
            it. We have experts to develop career plans
          </p>
        </div>
      </div>

      <div className="otherServices__Third">
        <div className="otherServices__Top">
          <WorkOutlineIcon style={{ fontSize: "36px" }} />
          <h1>Trainings</h1>
          <p className="services_Par">
            We provide variety of trainings and courses to improve your working
            skills. Our trainings are both in person and virtual.
          </p>
        </div>
        <div className="otherServices__bottom">
          <BuildOutlinedIcon style={{ fontSize: "36px" }} />
          <h1>Workshops</h1>
          <p className="services_Par">
            Out workshops will provide you with feedbacks, new ways of thinking,
            motivation to work, and new opportunities
          </p>
        </div>
      </div>
    </div>
  );
}
