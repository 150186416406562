import React from "react";
import "./style.css";
import Owner from "../../Assest/owner.jpg";
export default function About() {
  return (
    <div className="about">
      <h1>ABOUT US</h1>
      {/* <hr /> */}
      <p>
        We Can Win is a not-for-profit organization established in 2011 that has
        pioneered and guided about 600 immigrant families by training and
        advising the youth, newcomers, women, and seniors. Our goal is to help
        create opportunities and provide resources to improve the condition of
        visible minorities by working to enhance their skills for employment.
        Our mandate is to encourage participation in economic, cultural, social
        and political activities in the community, and to ensure individuals
        attain the resources in meeting future opportunities.
      </p>
      <img src={Owner} alt="" />
    </div>
  );
}
