import React from "react";
import emailjs from "emailjs-com";
import "./style.css";

export default function ContactUs() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "Gmail",
        "template_h92cjiq",
        e.target,
        "user_qOYDg25F3YPwMWgZHK6km"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div className="contact-section" style={{ marginTop: "50px" }}>
      <h1>Contact Us</h1>
      <div>
        <form
          onSubmit={sendEmail}
          style={{ margin: "30px auto", width: "500px" }}
        >
          <contactLabel name="number">Full Name</contactLabel>
          <input type="text" name="name" placeholder="Full Name" />
          <contactLabel name="number">Course's Name</contactLabel>
          <input type="text" name="name" placeholder="Full Name" />
          <contactLabel>Email</contactLabel>
          <input type="email" name="email" placeholder="Enter Your Email" />
          <contactLabel>Message</contactLabel>
          <textarea
            style={{ width: "430px" }}
            name="message"
            placeholder="Tell us why you want the course"
          />

          <input type="submit" value="Send" className="form__Btn" />
        </form>
      </div>
    </div>
  );
}
