import React from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
export default function Footer() {
  return (
    <footer>
      <div className="footer__Container">
        <div className="footer__Icons">
          <a href="https://www.facebook.com/wecanwin.ca">
            <img
              src="https://cdns.iconmonstr.com/wp-content/assets/preview/2012/240/iconmonstr-facebook-5.png"
              alt=""
            />
          </a>

          <a href="https://www.linkedin.com/company/we-can-win/?originalSubdomain=ca">
            <img
              src="https://cdns.iconmonstr.com/wp-content/assets/preview/2012/240/iconmonstr-linkedin-5.png"
              alt=""
            />
          </a>

          <a href="https://twitter.com/WeCanWinOnline">
            <img
              src="https://cdns.iconmonstr.com/wp-content/assets/preview/2012/240/iconmonstr-twitter-5.png"
              alt=""
            />
          </a>

          <a href="https://www.instagram.com/wecanwinglobal/">
            <img
              src="https://cdns.iconmonstr.com/wp-content/assets/preview/2016/240/iconmonstr-instagram-15.png"
              alt=""
            />
          </a>
        </div>
        <div className="footer__Links">
          <NavLink to="/" className="NavLink">
            <h1>Home</h1>
          </NavLink>
          <NavLink to="/about" className="NavLink">
            <h1>About</h1>
          </NavLink>
          <NavLink to="/courses" className="NavLink">
            <h1>Courses</h1>
          </NavLink>
          <NavLink to="/workshop" className="NavLink">
            <h1>Workshops</h1>
          </NavLink>
        </div>
        <div className="footer__Created">
          <h1>© 2021 We Can Win Global</h1>
        </div>
      </div>
    </footer>
  );
}
