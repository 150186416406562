import React from "react";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ContactUs from "./Contact";
export const PowerPoint = (props) => {
  return (
    <>
      <div className="course__Container">
        <div>
          <Link className="back" to="/Courses">
            <ChevronLeftIcon />
            <h3>Back</h3>
          </Link>
        </div>

        <div className="course">
          <div className="course__Hero">
            <h1> Microsoft Excel Tutorials</h1>
            <hr className="hero__HR" />
            <h2>
              Master Pivot Tables, Formulas, Macros, Data Analysis & More.
            </h2>

            <button>Click Here</button>
            <h3>Starting at CA$0.00/Month</h3>
          </div>

          <div className="course__title">
            <h1>MS Excel 2016 - Level 1</h1>
            <div className="powerPoint__About">
              <div class="parent">
                <p>Excel Basics</p>

                <ul>
                  <li>Creating An Excel Workbook</li>
                  <li>Opening an Existing Workbook</li>
                  <li>Creating a New Workbook</li>
                  <li>Saving a New Workbook</li>
                  <li>Saving a Workbook with a Different Name</li>
                  <li>Moving Between Open Workbooks</li>
                  <li>Setting Excel Options</li>
                  <li>Switching Between Views</li>
                  <li>Using Tell Me to Obtain Help</li>
                  <li>Closing a Workbook and Existing Excel</li>
                  <li>Adding the Exit Command to the Quick Access Toolbar</li>
                </ul>
              </div>

              <div class="parent">
                <p>Working with Data</p>

                <ul>
                  <li>Entering Text and Numbers</li>
                  <li>Entering Simple Formulas</li>
                  <li>Choosing Formula Cell References Manually</li>
                  <li>Using AutoSum</li>
                  <li>Changing the Contents of a Cell</li>
                  <li>Changing the Contents of a Cell</li>
                  <li>Using Undo</li>
                  <li>Using Redo</li>
                  <li>Using Find and Replace</li>
                  <li>Using the Go To Command</li>
                  <li>Spell Checking Your Worksheet</li>
                  <li>Inserting Symbols</li>
                  <li>Using Smart LookUp</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Editing a Worksheet</p>

                <ul>
                  <li>Working with Ranges</li>
                  <li>Copying and Pasting Data</li>
                  <li>Using the Office Clipboard</li>
                  <li>Cutting and Pasting Data</li>
                  <li>Copying and Moving Cells Using Drag-And-Drop</li>
                  <li>Changing Column Width (or Row Height)</li>
                  <li>Inserting Rows and Columns</li>
                  <li>Removing Rows and Columns</li>
                  <li>Copying Data and Formulas with AutoFill</li>
                  <li>Using AutoFill to Create a series</li>
                  <li>Filling in Cells Using Flash Fill</li>
                </ul>
              </div>

              <div class="parent">
                <p>Formatting a Worksheet</p>

                <ul>
                  <li>Formatting Text</li>
                  <li>Using the Format Cells Dialog box</li>
                  <li>Formatting Values</li>
                  <li>Using the Format Painter Button</li>
                  <li>Alignment and Text Wrapping</li>
                  <li>Merging Cells and Centering Text</li>
                  <li>Adding Cell Borders</li>
                  <li>Applying Colors to Cell text</li>
                  <li>Applying Shades to Cells (Background color)</li>
                  <li>Creating and Applying Cell Styles</li>
                  <li>Applying an Existing Cell Styles</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Editing a Worksheet</p>

                <ul>
                  <li>Working with Ranges</li>
                  <li>Copying and Pasting Data</li>
                  <li>Using the Office Clipboard</li>
                  <li>Cutting and Pasting Data</li>
                  <li>Copying and Moving Cells Using Drag-And-Drop</li>
                  <li>Changing Column Width (or Row Height)</li>
                  <li>Inserting Rows and Columns</li>
                  <li>Removing Rows and Columns</li>
                  <li>Copying Data and Formulas with AutoFill</li>
                  <li>Using AutoFill to Create a series</li>
                  <li>Filling in Cells Using Flash Fill</li>
                </ul>
              </div>

              <div class="parent">
                <p>Formatting a Worksheet</p>

                <ul>
                  <li>Formatting Text</li>
                  <li>Using the Format Cells Dialog box</li>
                  <li>Formatting Values</li>
                  <li>Using the Format Painter Button</li>
                  <li>Alignment and Text Wrapping</li>
                  <li>Merging Cells and Centering Text</li>
                  <li>Adding Cell Borders</li>
                  <li>Applying Colors to Cell text</li>
                  <li>Applying Shades to Cells (Background color)</li>
                  <li>Creating and Applying Cell Styles</li>
                  <li>Applying an Existing Cell Styles</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Page Setup and Printing</p>

                <ul>
                  <li>Selecting Standard Margins</li>
                  <li>Creating Custom Margins</li>
                  <li>Setting Page Orientation and Setting Paper Size</li>
                  <li>Defining a Print Area</li>
                  <li>Printing Worksheet Titles</li>
                  <li>Forcing a Worksheet to Fit</li>
                  <li>Inserting Headers and Footers</li>
                  <li>Printing a Worksheet</li>
                </ul>
              </div>

              <div class="parent">
                <p>Formulas and Functions</p>

                <ul>
                  <li>Relative Cell References</li>
                  <li>Absolute Cell References</li>
                  <li>Mixed References</li>
                  <li>Copying Formulas</li>
                  <li>MIN, MAX, COUNT and AVERAGE Functions</li>
                  <li>The Insert Function Button</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Modifying Workbooks</p>

                <ul>
                  <li>Adding Worksheets</li>
                  <li>Adding Worksheets</li>
                  <li>Deleting Worksheets</li>
                  <li>Copying Worksheets</li>
                  <li>Renaming Worksheets</li>
                  <li>Repositioning Worksheets</li>
                  <li>Grouping Worksheets</li>
                  <li>Ungrouping Worksheets</li>
                  <li>Changing Worksheet Tab Colors</li>
                  <li>Creating 3-D Formulas</li>
                </ul>
              </div>

              <div class="parent">
                <img
                  style={{
                    height: "380px",
                    width: "320px",
                    objectFit: "contain",
                  }}
                  src="https://images.pexels.com/photos/3727511/pexels-photo-3727511.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                  alt=""
                />
              </div>
            </div>

            <h1>MS Excel 2016 - Level 2</h1>

            <div className="powerPoint__About">
              <div class="parent">
                <p>Working with Tables</p>

                <ul>
                  <li>Creating a table</li>
                  <li>Enter data into a table</li>
                  <li>Deleting rows and columns from a table</li>
                  <li>Formatting a table</li>
                  <li>Formatting table elements</li>
                  <li>Totaling data in a table</li>
                  <li>Sorting data in a table</li>
                  <li>Sorting by multiple columns</li>
                  <li>Filtering data using AutoFilter</li>
                  <li>Creating custom filters</li>
                </ul>
              </div>

              <div class="parent">
                <p>Working with Charts</p>

                <ul>
                  <li>Creating a chart</li>
                  <li>Moving a chart</li>
                  <li>Resizing a chart</li>
                  <li>Changing the layout and style</li>
                  <li>Labeling chart elements</li>
                  <li>Formatting chart text</li>
                  <li>Formatting chart elements</li>
                  <li>Changing the chart type</li>
                  <li>Showing or hiding gridlines</li>
                  <li>Customizing axes</li>
                  <li>Creating pie chart</li>
                  <li>Changing a chart’s source data</li>
                  <li>Deleting a data series</li>
                  <li>Moving a chart to a different worksheet</li>
                  <li>Saving a chart template</li>
                  <li>Filtering chart data</li>
                  <li>Using sparklines</li>
                  <li>Customizing sparklines</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Working with Graphics</p>

                <ul>
                  <li>Adding pictures</li>
                  <li>Inserting online images</li>
                  <li>Adding shapes</li>
                  <li>Formatting drawing objects</li>
                  <li>Inserting WordArt</li>
                  <li>Inserting SmartArt</li>
                  <li>Inserting an organization chart</li>
                  <li>Adding New Shapes/Relationships</li>
                  <li>Formatting an organization chart</li>
                  <li>Taking a screen shot</li>
                </ul>
              </div>

              <div class="parent">
                <p>Workgroup Collaboration</p>

                <ul>
                  <li>E-mailing a workbook</li>
                  <li>Adding web page preview to Quick Access Toolbar</li>
                  <li>Viewing a workbook in web page preview</li>
                  <li>Converting worksheets into web pages</li>
                  <li>Inserting hyperlinks</li>
                  <li>Adding comments</li>
                  <li>Editing an existing comment</li>

                  <li>Deleting an existing comment</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Financial and Logical Functions</p>

                <ul>
                  <li>Using the IF function</li>
                  <li>Using nested functions</li>
                  <li>Using the PMT function</li>
                  <li>Using the FV function</li>
                  <li>Using AutoCalculate</li>
                </ul>
              </div>

              <div class="parent">
                <p>Date and Time Functions</p>

                <ul>
                  <li>Understanding Date/Time functions</li>
                  <li>Adding a date and a date interval</li>
                  <li>Subtracting dates</li>
                  <li>Calculating time intervals</li>
                </ul>
              </div>
            </div>

            <div className="powerPoint__About" style={{ marginTop: "100px" }}>
              <div class="parent">
                <p>Managing Workbooks</p>

                <ul>
                  <li>Creating a workbook using a template</li>
                  <li>Creating a new template</li>
                  <li>Editing a template</li>
                  <li>Showing or hiding workbook elements</li>
                  <li>Creating a workspace</li>
                  <li>Comparing two workbooks side by side</li>
                  <li>Saving a workbook in a different file format</li>
                  <li>Using data consolidation</li>
                </ul>
              </div>

              <div class="parent">
                <img
                  style={{
                    height: "380px",
                    width: "320px",
                    objectFit: "contain",
                  }}
                  src="https://images.pexels.com/photos/3727511/pexels-photo-3727511.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
                  alt=""
                />
              </div>
            </div>
          </div>

          <ContactUs className="contact__Courses" />
        </div>
      </div>
    </>
  );
};

export default PowerPoint;
