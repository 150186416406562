import React from "react";
import { useEffect } from "react";

import { Link } from "react-router-dom";

const Courses = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="coursesSection">
        <div className="coursesSection__List">
          <div className="coursesSection__ListItem">
            <img src={props.img} alt="" />
            <div className="coursesSection__ListItem__Desc">
              <h1>{props.title}</h1>
              <p>-------</p>

              <p>{props.description}</p>
            </div>
            <div className="CoursesSection__ListItem__Price">
              <h1>+</h1>
              <Link to={{ pathname: `/${props.link}` }}>
                <button className="link">
                  {" "}
                  <div className="course__Link">See More</div>
                </button>
              </Link>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default Courses;
