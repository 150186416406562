import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
export default function Hero() {
  return (
    <div className="heroCourses">
      <div className="heroCourses__Left">
        <h1>Helping you learn for the modern age.</h1>
        <p>
          We Can Win Global provides varity of different courses for its members
        </p>

        <button>
          {" "}
          <Link to="/workshop" className="course__Link">
            Check Workshops
          </Link>{" "}
        </button>
      </div>
    </div>
  );
}
