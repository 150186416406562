import React from "react";
import "./style.css";
import Flip from "react-reveal/Flip";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SchoolIcon from "@material-ui/icons/School";
import BuildIcon from "@material-ui/icons/Build";

export default function Assestance() {
  const services = data.map((service) => (
    <>
      <div className="assestances">
        <span>{service.icon}</span>

        <h1>{service.name}</h1>
        <hr />
        <Flip left>
          <p>{service.numbers}</p>
        </Flip>
      </div>
    </>
  ));

  return (
    <div className="assest">
      <div className="assest__Container">{services}</div>
    </div>
  );
}

const data = [
  {
    name: "How many people we helped",
    numbers: "600+",
    icon: <PeopleOutlineIcon style={{ fontSize: "46px" }} />,
  },
  {
    name: "Schools collaborated",
    numbers: "12",
    icon: <SchoolIcon style={{ fontSize: "46px" }} />,
  },
  {
    name: "Staff and Volunteers",
    numbers: "70",
    icon: <AccessTimeIcon style={{ fontSize: "46px" }} />,
  },
  {
    name: "Workshops provided",
    numbers: "20",
    icon: <BuildIcon style={{ fontSize: "46px" }} />,
  },
];
