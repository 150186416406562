import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Flip from "react-reveal/Flip";
export default function Hero() {
  return (
    <div className="hero">
      <div className="hero__Left">
        <Flip top duration={2000}>
          <h1>
            WE CAN WIN GLOBAL <hr />
          </h1>
        </Flip>
        <hr />
        {/* <img src={funded} alt="" /> */}
        <button>
          <Link className="about-Link" to="/about">
            Know More
          </Link>
        </button>
      </div>
      <div className="hero__right"></div>
    </div>
  );
}
