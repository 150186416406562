import React from "react";
import emailjs from "emailjs-com";
import Map from "./Map";
import "./style.css";

export default function ContactUs() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "Gmail",
        "template_h92cjiq",
        e.target,
        "user_1qyFrsMoxFY3BJL4QfPw2"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div className="contact-section">
      <h1>Contact Us</h1>
      <div className="contact__Container">
        <Map />
        <form onSubmit={sendEmail}>
          <contactLabel name="number">Full Name</contactLabel>
          <input type="text" name="name" placeholder="Full Name" />
          <contactLabel name="number">Course's Name</contactLabel>
          <input type="text" name="name" placeholder="Full Name" />
          <contactLabel>Email</contactLabel>
          <input type="email" name="email" placeholder="Enter Your Email" />
          <contactLabel>Contact Number</contactLabel>
          <input type="email" name="email" placeholder="Enter Your Email" />
          <contactLabel>Message</contactLabel>
          <textarea name="message" placeholder="Type..." />

          <input type="submit" value="Send" className="form__Btn" />
        </form>
      </div>
    </div>
  );
}
