import React from "react";
import "./style.css";
import Youth from "../../Assest/Youth.jpg";
import Women from "../../Assest/Women.jpg";
import Seniors from "../../Assest/Seniors.jpg";
export default function Services() {
  const services = data.map((service) => (
    <>
      <div className="services__First">
        <img src={service.img} alt="" />
        <h1>{service.name}</h1>
        <p>{service.paragraph}</p>
      </div>
    </>
  ));

  return (
    <>
      <div className="services">
        <h1>Services</h1>
        <div className="services__Info">{services}</div>
      </div>
      <hr style={{ marginTop: "50px" }} />
    </>
  );
}

const data = [
  {
    img: Youth,
    name: "YOUTH",
    paragraph:
      "The youths role is shaping our future, therefore, we provide the best resources to improve their future.",
  },
  {
    img: Women,
    name: "WOMEN",
    paragraph:
      "Women in the current work market are considered as a minority, now join us to help overcome that issue.",
  },
  {
    img: Seniors,
    name: "SENIORS",
    paragraph:
      "Seniors are struggling to adapt with the new job requirements, with We Can Win Global, seniors will have the best recourses to get them into the right job.",
  },
];
