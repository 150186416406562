import React from "react";
import "./style.css";
export default function Workshop() {
  return (
    <>
      <div className="course">
        <div className="workshop__Hero"></div>
      </div>
      <div className="workshop">
        <h1>Target Audience</h1>
        <div className="workshop__info">
          <ul>
            <li>New Immigrants</li>
            <li>Aspiring Project Management Students</li>
            <li>Business Analysts</li>
            <li>Management (MBA) Graduates</li>
            <li>IT hardware, software Professionals</li>
            <li>Gradutes, in the process of graduation</li>
            <li>Youth in start up Careers</li>
            <li>Women in start up Careers</li>
          </ul>
        </div>
        <h1>Professional and Management Development Training</h1>
        <h1>Canada has the highest rate of tertiary education</h1>

        <div className="workshop__Info__More">
          <p>
            By putting ‘perseverance, engaging with teams developing continuous
            culture of learning to take you a step ahead in your goal to
            establish career in Project Management
          </p>
          <p>Business Analysis</p>
          <p>Quality Assurance Analysis (Tools)</p>
          <p>MS Office (2010 and 2013 MS Word, Excel, Power point)</p>
        </div>
        <div className="workshop__Info__Plan">
          <ul>
            <li>
              Identify the goal, and objectives to reach the goal (Job or career
              start up in business Analysis Management or similar interest and
              passion)
            </li>
            <li>
              Make the client understand the foundation of training in projects,
              concepts, professional Terms.
            </li>
            <li>
              Interactive sessions, view webinars, meetings for innovative
              ideas, brain storming sessions to be instrumental in the
              job/careers.
            </li>
            <li>
              Step to become smart, innovative, creative personality, achieve
              your goal
            </li>
            <li>Create plan to start up workshops, webinars.</li>
            <li>Gauge effectiveness of training & adjust accordingly</li>
          </ul>
        </div>
      </div>
    </>
  );
}
