import React from "react";
import Courses from "./Courses";
import Hero from "./Hero";
export default function ResourcesContainer() {
  return (
    <>
      <div className="main-content">
        <Hero />
        <h1 className="Cource__Title">Resources</h1>

        <Courses
          img={
            "https://cdn.worldvectorlogo.com/logos/microsoft-word-2013-logo.svg"
          }
          link="words"
          title="Microsoft Words"
          description="This program will be teaching you the fundemental of Microsoft Words to get you started with your journey. The course require require any previous expernice with Microsoft Words."
        />

        <Courses
          img={
            "https://cdn.worldvectorlogo.com/logos/microsoft-powerpoint-2013.svg"
          }
          link="powerpoint"
          title="Microsoft PowerPoint"
          description="This program will be teaching you the fundemental of Microsoft Powerpoint to get you started with your journey. The courserequire require any previous expernice with Microsoft Words."
        />

        <Courses
          img={"https://cdn.worldvectorlogo.com/logos/microsoft-excel-2013.svg"}
          link="excel"
          title="Microsoft Excel"
          description="This program will be teaching you the fundemental of Microsoft Excel to get you started with your journey. The course require require any previous expernice with Microsoft Words."
        />

        <Courses
          img={
            "https://www.learntek.org/blog/wp-content/uploads/2018/03/BUSINESS.png"
          }
          link="business"
          title="Business Analysis"
          description="With this course, you will have the knowledge to run your own business from scratch. We teach you how to develop the perfect skills to improve or build your own business."
        />

        <Courses
          img={
            "https://thumbs.dreamstime.com/b/property-management-operation-control-oversight-real-estate-business-concept-property-management-operation-140887738.jpg"
          }
          link="management"
          title="Management Course"
          description="The Full Management course offers you full control of your business and a grant that will increase your profits. We offer profissional analysis, data processing, and analytic process.. With us, you will be joyful and happy."
        />

        <Courses
          img={
            "https://enterprisersproject.com/sites/default/files/styles/620x350/public/images/cio_women_leadership.png?itok=sIbyEZ-S"
          }
          link="leadership"
          title="Leadership Course"
          description="The Leadership program is a program that is designed for the ones who are very intrested in being a well developed leader. We provide you all the necessary materials for students in order to achieve your goals"
        />
      </div>
    </>
  );
}
