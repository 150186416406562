import React from "react";
import About from "./About/About";
import Assestance from "./Assestance/Assestance";
import Contact from "./Contact/Contact";
import Hero from "./Hero/Hero";
import Services from "./Services/Services";
import A from "./otherServices/otherServices";

export default function Homepage() {
  return (
    <div>
      <Hero />
      <About />
      <Assestance />
      <Services />
      <A />
      <Contact />
    </div>
  );
}
