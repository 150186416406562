import React from "react";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ContactUs from "./Contact";
export const PowerPoint = (props) => {
  return (
    <>
      <div className="course__Container">
        <div>
          <Link className="back" to="/Courses">
            <ChevronLeftIcon />
            <h3>Back</h3>
          </Link>
        </div>

        <div className="course">
          <div className="course__Hero">
            <h1> PowerPoint Tutorials</h1>
            <hr className="hero__HR" />
            <h2>
              Designing completing slide, presentations, editing, sharing &
              more.
            </h2>

            <button>Click Here</button>
            <h3>Starting at CA$0.00/Month</h3>
          </div>

          <div className="course__title">
            <h1>About The Course</h1>
            <p>
              This class is designed to give a basic introduction to Microsoft
              PowerPoint 2010. Specifically, we will progress from learning how
              to open Microsoft PowerPoint to actually creating a slideshow. It
              is impossible in two hours to become totally proficient using
              Microsoft PowerPoint, but it is my hope that this class will
              provide a springboard to launch you into this exciting world!.
            </p>

            <h1>Course Objectives</h1>

            <p>By the end of this course, you will :</p>

            <div class="parent">
              <ul>
                <li>
                  Have a basic understanding of the Office Ribbon in Microsoft
                  Office.
                </li>
                <li>
                  Know how to start a new presentation in Microsoft PowerPoint,
                  save it, and be able to find it later.
                </li>
                <li>Insert text in Microsoft PowerPoint.</li>
                <li>
                  Know how to add transitions and animations to your slides.
                </li>
                <li>Be able to add images to your presentation.</li>
                <li>Know how to start, navigate, and exit your slideshow.</li>
                <li>Know how to print.</li>
              </ul>
            </div>

            <h1>About Course</h1>
            <p>Chapter 1: PowerPoint Basics</p>
            <div className="powerPoint__About">
              <div class="parent">
                <ul>
                  <li>Inserting a New Slide</li>
                  <li>Views</li>
                  <li>Normal View</li>
                  <li>Slide Sorter View</li>
                  <li>Slide Show</li>
                  <li>Notes Page</li>
                </ul>
              </div>

              <div class="parent">
                <ul>
                  <h1 className="powerPoint__h1">
                    Chapter 2: Inserting a Graph & Diagram
                  </h1>
                  <h1 className="powerPoint__h1">
                    Chapter 3: Inserting a Picture
                  </h1>
                  <h1 className="powerPoint__h1">
                    Chapter 4: Inserting a Sound
                  </h1>
                  <h1 className="powerPoint__h1">
                    Chapter 5: Inserting a Video
                  </h1>
                  <h1 className="powerPoint__h1">Chapter 6: Saving at EIT :</h1>
                  <li>Saving your Document</li>
                  <li>Saving to a USB Memory Stick</li>
                </ul>
              </div>
            </div>

            <h1>What Will You Do</h1>
            <p>
              This PDF booklet will serve as a guide as we progress through the
              class, but it can also be a valuable tool when you are working on
              your own. Any class instruction is only as effective as the time
              and effort you are willing to invest in it. I encourage you to
              practice soon after class. There will be additional computer
              classes in the near future, and I am available for questions
              during Tech Time, generally on Tuesdays from 10am-noon and
              Thursdays from 3-5 pm. Feel free to call to confirm the time.
            </p>
          </div>

          {/* <ContactUs /> */}
        </div>
      </div>
      <ContactUs className="contact__Courses" />
    </>
  );
};

export default PowerPoint;
