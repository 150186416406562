import React from "react";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ContactUs from "./Contact";
export default function Managment() {
  return (
    <>
      <div className="course__Container">
        <div>
          <Link className="back" to="/Courses">
            <ChevronLeftIcon />
            <h3>Back</h3>
          </Link>
        </div>

        <div className="course">
          <div className="course__Hero">
            <h1> PowerPoint Tutorials</h1>
            <hr className="hero__HR" />
            <h2>
              Designing completing slide, presentations, editing, sharing &
              more.
            </h2>

            <button>Click Here</button>
            <h3>Starting at CA$0.00/Month</h3>
          </div>

          <div className="course__title">
            <h1>Managing Projects:</h1>
            <p>
              With a personal vision of how your team will perform, will be able
              to meet the goal.
            </p>

            <h1>Creating the culture</h1>

            <p>
              If planning to hire, to develop a performing team Identify what
              your team must achieve, Empower to make operational decisions for
              the benefit of the project.
            </p>
            <p>
              Identify team culture and what to build Team dynamics Work
              together.
            </p>

            <h1>Who Should Take The Course</h1>

            <div class="parent">
              <ul>
                <li> A new project manager and/or functional manager</li>
                <li>who wish to learn/hone project management skills</li>
                <li>Head of a corporate department</li>
                <li>Have business planning responsibilities</li>
                <li>Work in systems, manufacturing, and operations</li>
                <li>
                  General managers, business managers, and human resources
                  managers
                </li>
              </ul>
            </div>

            <h1>Introduction</h1>
            <p>
              Whether you’re a novice or an expert, you can always improve the
              way you manage projects The more you improve, the greater the
              level of project success you will achieve. To help you do this,
              we’ll step you through the things you can do to improve the way
              you manage projects on a daily basis.{" "}
            </p>

            <h1>What You Will Learn</h1>

            <div className="powerPoint__About">
              <div class="parent">
                <ul>
                  <li>Set goals and achieve them</li>
                  <li>Improve your decision-making skills </li>
                  <li>Manage day-to-day tasks</li>
                  <li>Boost your personal confidence </li>
                </ul>
              </div>

              <div class="parent">
                <ul>
                  <li>Write great project documents</li>
                  <li>Deliver fantastic presentations</li>
                  <li>Improve your learning</li>
                  <li>Get a work-life balance</li>
                </ul>
              </div>
            </div>

            <p style={{ marginTop: "60px" }}>
              If you apply all of the tips, tricks and techniques provided in
              this eBook, you’ll be on the right track to becoming a better
              Project Manager.{" "}
            </p>
            <h3 className="management__H3">Building high performing teams</h3>
            <h1>Introduction</h1>
            <p>
              It’s fair to say that high performing managers have a gifted
              ability to hire, lead and manage high performing teams. If that’s
              someone you’d like to be, then nd out how to do it by reading this
              eBook.
            </p>

            <h1>What You Will Learn</h1>

            <div className="powerPoint__About">
              <div class="parent">
                <ul>
                  <li>Hire the right team</li>
                  <li>Lead by example </li>
                  <li>Motivate staff </li>
                </ul>
              </div>

              <div class="parent">
                <ul>
                  <li>Manage politics and conflict</li>
                  <li>Reduce stress levels</li>
                  <li>Run effective meetings</li>
                </ul>
              </div>
            </div>
            <p style={{ marginTop: "50px" }}>
              Of course there’s no silver bullet to managing projects. But if
              you can hire, lead and motivate a quality team to achieve the
              project goals then it’s a great start! It all depends on you
              really. Don’t get bogged down with the project at the micro level,
              instead step back and take a helicopter view. Hire the right
              people and trust them to do the job well. Lead and motivate these
              people, offering them help and support when they need it. Your job
              is to be the ship’s captain: you set the course, motivate the team
              and keep the ship on track but you don’t scrub the decks or hoist
              the rigging! With that in mind, here’s how to build high
              performing teams.{" "}
            </p>
            <h1>Get The Full Course</h1>
            <p>
              Live Link :
              <a
                style={{
                  textDecoration: "none",
                  color: "black",
                  borderBottom: "1px solid black",
                }}
                href="https://drive.google.com/file/d/1V9UJAQXhK2R2bgONAP-iA-CgCs39FKYB/view?usp=sharing"
              >
                {" "}
                Full Managment Course
              </a>
            </p>
          </div>
          <ContactUs className="contact__Courses" />
        </div>
      </div>
    </>
  );
}
