import React from "react";
import "./style.css";
import Logo from "../../Assest/logo.png";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <nav>
      <img src={Logo} alt="" />
      <ul>
        <li>
          {" "}
          <Link to="/" className="nav-link">
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className="nav-link">
            About
          </Link>
        </li>
        <li>
          <Link to="/Resources" className="nav-link">
          Resources
          </Link>
        </li>

        <li>
          <Link to="/workshop" className="nav-link">
            Workshops
          </Link>
        </li>
        <li>
          {" "}
          <Link to="/Contact" className="nav-link">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}
