import React from "react";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ContactUs from "./Contact";

export default function Business() {
  return (
    <div className="course__Container">
      <div>
        <Link className="back" to="/Courses">
          <ChevronLeftIcon />
          <h3>Back</h3>
        </Link>
      </div>

      <div className="course">
        <img
          className="business__Hero"
          src="https://mail.google.com/mail/u/0?ui=2&ik=7c2a19e420&attid=0.1&permmsgid=msg-f:1690882231823968616&th=1777386e60e32968&view=fimg&sz=s0-l75-ft&attbid=ANGjdJ-cITTrzLZ5UBoAiW-WmAqNQABJxopLTckHNTb7T7Bpeu8R7XcMoPlf1HDAxrhbHDtce2nO_tNG4M79FoBfKQ5LopnRapaNd5KcTy5CClpXmL3Ury5c8NsXc_E&disp=emb&realattid=ii_kksn55f60"
          alt=""
        />

        <div className="course__title">
          <h1>What Will You Learn</h1>

          <div class="parent">
            <ul>
              <li>
                Create business models with a goal to increase Return on
                Investment ROI
              </li>
              <li>Create a new( or update existing) Business plan</li>
              <li>
                Create Business requirement and Functional requirements plan
              </li>
            </ul>
          </div>

          <h1>Business Operations :</h1>
          <div class="parents">
            <ul>
              <li>Determine workflow (Business Processes)</li>
              <li>Build business models</li>
              <li>Create ‘As is & To be’ Business Model</li>
              <li>
                Who what where when & how the clients business is presently &
                what are the needs desires to be in the future.
              </li>
              <li>
                Reduce repetitive processes by identifying redundant activities,
              </li>
              <li> increase automation and reduce time in processing.</li>
              <li>
                Monitor services to ensure the progress of developed
                products/services,
              </li>
              <li>meet predetermined objectives.</li>
              <li>
                Reduce resources time & money by gaining skills in business
                analysis, project management, quality analysis and management
                tools, software to increase profits.
              </li>
              <li>
                Identify the Impact in work ( use techniques of business
                analysis, management and positive results
              </li>
              <li>
                Identify the scope of work, propose strategic plan, project
                plan, alignment with existing plans, and communicate with the
                business owners.
              </li>
              <img
                className="business__Img"
                src="https://mail.google.com/mail/u/0?ui=2&ik=7c2a19e420&attid=0.3&permmsgid=msg-f:1690882231823968616&th=1777386e60e32968&view=fimg&sz=s0-l75-ft&attbid=ANGjdJ9Bb3Z9y_XFpib0jmKciq95IVvhtW2duTRNgnfs-nCr5rjtuvIdT7xQ_sQ9BLfBK57d6lhOCSXKWErEmFip5b_yenCS1qtBdEcEn25NOU79YngQLq4ejKBj1K8&disp=emb&realattid=ii_kksn59at2"
                alt=""
              />
            </ul>
          </div>

          <p>
            These Projects need Business Analysts, Managers, to perform the
            below activities not inclusively :{" "}
          </p>

          <div class="parent">
            <ul>
              <li>
                Strategically refine define vision, mission, goals, objectives.
              </li>
              <li>identify the need & analyse</li>
              <li>
                assess solutions for small, medium by following methodology in
                businesses.
              </li>
              <li> prepare business process</li>
              <li>Adopt to a formal documentation.</li>
              <li>Creating business artefacts.</li>
              <li>
                after detailed analysis & solutions match expected intended
                outcome after the research & insights and making a decision.
              </li>
              <li>Identify activities, prepare deliverables.</li>
              <li>
                Plan, determine the scope of work for the activities, determine
                activities in ‘Assessment model’
              </li>
              <li>
                Resourceful team with previous working experience for medium to
                large size companies
              </li>
            </ul>
          </div>

          <h1>Functionally :</h1>

          <div class="parent">
            <ul>
              <li>Assess & provide solution.</li>
              <li>Prepare requirement document</li>
              <li>
                Identifying the business life cycle (Software development life
                cycle for IT projects)
              </li>
              <li>Identifying the functionality</li>
              <li>Validate solutions</li>
            </ul>
          </div>

          <h1>Operationally:</h1>

          <div class="parent">
            <ul>
              <li>
                Developed the Business re-engineering, business process
                improvement.
              </li>
              <li>
                (AS IS) for identifying gaps for business process improvements.
              </li>
              <li>Identify systems design</li>
            </ul>
          </div>

          <h1>Strategically:</h1>

          <div className="parents">
            <ul>
              <li>Prepare business objectives</li>
              <li>
                Implement SDLC, RUP, ALM agile methodologies in analysis,
                design, and implementation and testing Phases.
              </li>
              <li>
                Develop Business case to solve a problem or achieve an objective
                or seize a market opportunity by taking advantage of present
                market & gain profit.
              </li>
              <li>Excellent Interpersonal communications skills.</li>
              <li>Ability to develop and motivate team members.</li>
              <li>
                Negotiate with representative from other areas within the
                organization.
              </li>
              <li>
                Development in corporate expansion into emerging markets mergers
                and acquisitions.
              </li>
              <li>
                Increased automation - technology has driven down the costs of
                equipment that offer technological efficiencies. Using
                technology reduces the dependence on human labour and cost
              </li>
              <img
                className="business__Img"
                src="https://mail.google.com/mail/u/0?ui=2&ik=7c2a19e420&attid=0.4&permmsgid=msg-f:1690882231823968616&th=1777386e60e32968&view=fimg&sz=s0-l75-ft&attbid=ANGjdJ80oFjNtnoRKS1qb-QYS-LHgLW-GTwRurgk3AHOsL78z3IeGrlEbncz9PIf6w9YsnvCEuZVu78M7iaS94UUVVBWL1aEFH6G43KxCZJMO92RjJBQvkXQ1_AVHSE&disp=emb&realattid=ii_kksn5aqh3"
                alt=""
              />
            </ul>
          </div>

          <p>
            Creating a conducive environment: Considering the lack of time and
            money required by small and medium scale business establishments for
            developing technical improvements to their business operation to
            keep in line with the prevailing market expectation set-period
            project contracts could substitute the need and attain the ultimate
            objective of the business unit with less cost and specialized
            service. There is room for expansion and brand identification.
            Availability of easy access to the system analyst and product
            developers, low project costs.
          </p>

          <h1>Advisors :</h1>

          <p style={{ textAlign: "center" }}>
            {" "}
            Advisories in the 3P’s processes, people, and products or services
            for :
          </p>
          <div className="parents">
            <ul>
              <li> increase in benefits.</li>
              <li> reduce costs,</li>
              <li> increase in live projects,</li>
              <li> decrease work (cost) by automation.</li>
            </ul>
          </div>

          <h1>Leadership</h1>
          <p className="business__title">Level 1:</p>
          <p>
            What position you want to be in the next 1-3 years, 5 Years, 10
            years from now?
          </p>
          <p>Do you know your resources in Canada?</p>
          <p>What are your ambitions?</p>
          <p>What is the level of energy, level of effort, and mind?</p>

          <p className="business__title">Level 2:</p>
          <p>Connections: are you connected with people,</p>
          <p>
            What is relationship in the work environment? likeable,
            relationships with people is the foundation
          </p>
          <p>
            Listen well, constantly observe them & learning process, have an
            attitude of serve, mindset, culture.
          </p>

          <p className="business__title">Level 3 :</p>

          <p>Increase in production level, results,</p>
          <p>
            {" "}
            effective, create momentum, momentum solves problem solver, 80
            percent of results come from only 20 percent of the causes for a
            given situation.
          </p>
          <p>Commit to development</p>
          <p> increase capacity</p>

          <p className="business__title">Level 4 :</p>
          <p>
            Pinnacle level respect absolutely, follow great respect, results in
            a life journey
          </p>
          <p>Communicate & connect, follow 3 principles.</p>
          <p>connecting all about others.</p>
        </div>

        <ContactUs className="contact__Courses" />
      </div>
    </div>
  );
}
