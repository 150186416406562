import React from "react";
import { Switch, Route } from "react-router-dom";
import About from "./Components/About/About";
import Homepage from "./Components/Homepage/Homepage";
import Navbar from "./Components/Layout/Navbar/Navbar";
import words from "./Components/Products/words";
import PowerPoint from "./Components/Products/PowerPoint";
import Excel from "./Components/Products/Excel";
import Business from "./Components/Products/Business";
import Workshop from "./Components/Workshop.js/Workshop";
import Managment from "./Components/Products/Managment";
import Leadership from "./Components/Products/Leadership";
import ResourcesContainer from "./Components/Products/ResourcesContainer";
import Footer from "./Components/Layout/Footer/Footer";
import ContactUs from "./Components/Products/Contact";

function App() {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Homepage} />
        <Route exact path="/about" component={About} />
        <Route exact path="/workshop" component={Workshop} />
        <Route exact path="/words" component={words} />
        <Route exact path="/business" component={Business} />
        <Route exact path="/powerpoint" component={PowerPoint} />
        <Route exact path="/excel" component={Excel} />
        <Route exact path="/management" component={Managment} />
        <Route exact path="/leadership" component={Leadership} />
        <Route exact path="/contact" component={ContactUs} />
        <Route exact path="/Resources" component={ResourcesContainer} />
      </Switch>
      <Footer />
    </>
  );
}

export default App;
